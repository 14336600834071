import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import { BLOG_LIST } from "./blog-list";
import { Helmet } from "react-helmet";

const blogPosts = BLOG_LIST;

function Blog() {
  return (
    <Fragment>
      <div>
        <Helmet>
          <title>Our Blogs | OGM Business Consultants</title>
          <meta
            name="description"
            content="Explore expert insights, strategies, and tips for business growth from OGM Business Consultants' blog. Stay updated with the latest trends in business consulting, finance, management, and more. Discover how to drive success in your industry with actionable advice from our experienced consultants."
          />
        </Helmet>
        <Navmenu />
        <div
          className="container-fluid bg-primary py-5 bg-header"
          style={{ marginBottom: "90px" }}
        >
          <div className="row py-5">
            <div className="col-12 pt-lg-5 mt-lg-5 text-center">
              <h1 className="display-4 text-white animated zoomIn">Blog</h1>
              <a href className="h5 text-white">
                Home
              </a>
              <i className="far fa-circle text-white px-2" />
              <a href className="h5 text-white">
                Blog Grid
              </a>
            </div>
          </div>
        </div>
        {/* Navbar End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ maxWidth: "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Blog Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              {/* Blog list Start */}
              <div className="col-lg-8">
                <div className="row g-5">
                  <div className="col-md-6 wow slideInUp" data-wow-delay="0.1s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                      <div className="blog-img position-relative overflow-hidden">
                        <img
                          className="img-fluid"
                          src="assets/img/blog-1.jpg"
                          alt=""
                        />
                        <a
                          className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                          href
                        >
                          Business Formation
                        </a>
                      </div>
                      <div className="p-4">
                        <div className="d-flex mb-3">
                          <small className="me-3">
                            <i className="far fa-user text-primary me-2" />
                            Mckinzie
                          </small>
                          <small>
                            <i className="far fa-calendar-alt text-primary me-2" />
                            22 Aug, 2024
                          </small>
                        </div>
                        <h4 className="mb-3">
                          Why Do Startups Need Business Setup Consultants More
                          Than Ever?
                        </h4>
                        <p>
                          With the ever-changing business world, new businesses
                          face unique issues that require expert understanding
                          and guidance.
                        </p>
                        <a className="text-uppercase" href="/Blog-page?id=1">
                          Read More <i className="bi bi-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 wow slideInUp" data-wow-delay="0.6s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                      <div className="blog-img position-relative overflow-hidden">
                        <img
                          className="img-fluid"
                          src="assets/img/blog-2.jpg"
                          alt=""
                        />
                        <a
                          className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                          href
                        >
                          Advantages
                        </a>
                      </div>
                      <div className="p-4">
                        <div className="d-flex mb-3">
                          <small className="me-3">
                            <i className="far fa-user text-primary me-2" />
                            Glen
                          </small>
                          <small>
                            <i className="far fa-calendar-alt text-primary me-2" />
                            22 Aug, 2024
                          </small>
                        </div>
                        <h4 className="mb-3">
                          What Are the Key Benefits of Outsourcing Payroll
                          Services?
                        </h4>
                        <p>
                          Managing payroll is a crucial and challenging job for
                          firms of any size.
                        </p>
                        <a className="text-uppercase" href="/Blog-page?id=2">
                          Read More <i className="bi bi-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 wow slideInUp" data-wow-delay="0.1s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                      <div className="blog-img position-relative overflow-hidden">
                        <img
                          className="img-fluid"
                          src="assets/img/blog-3.jpg"
                          alt=""
                        />
                        <a
                          className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                          href
                        >
                          Guidelines
                        </a>
                      </div>
                      <div className="p-4">
                        <div className="d-flex mb-3">
                          <small className="me-3">
                            <i className="far fa-user text-primary me-2" />
                            Park
                          </small>
                          <small>
                            <i className="far fa-calendar-alt text-primary me-2" />
                            22 Aug, 2024
                          </small>
                        </div>
                        <h4 className="mb-3">
                          What Are the Common Mistakes to Avoid in Residence
                          Visa Applications?
                        </h4>
                        <p>
                          Applying for a visa to reside in Dubai is essential
                          for those intending to work and live for some time in
                          the UAE.
                        </p>
                        <a className="text-uppercase" href="/Blog-page?id=3">
                          Read More <i className="bi bi-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 wow slideInUp" data-wow-delay="0.6s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                      <div className="blog-img position-relative overflow-hidden">
                        <img
                          className="img-fluid"
                          src="assets/img/blog-1.jpg"
                          alt=""
                        />
                        <a
                          className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                          href
                        >
                          Services
                        </a>
                      </div>
                      <div className="p-4">
                        <div className="d-flex mb-3">
                          <small className="me-3">
                            <i className="far fa-user text-primary me-2" />
                            Shinkai
                          </small>
                          <small>
                            <i className="far fa-calendar-alt text-primary me-2" />
                            22 Aug, 2024
                          </small>
                        </div>
                        <h4 className="mb-3">
                          Where Can You Get Affordable Tax Consulting Services?
                        </h4>
                        <p>
                          The complex tax landscape can be challenging for
                          businesses and individuals. For those in the UAE in
                          the UAE, where the tax system has changed dramatically
                          in recent decades, consulting with an expert is now
                          essential.
                        </p>
                        <a className="text-uppercase" href="/Blog-page?id=4">
                          Read More <i className="bi bi-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 wow slideInUp" data-wow-delay="0.1s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                      <div className="blog-img position-relative overflow-hidden">
                        <img
                          className="img-fluid"
                          src="assets/img/blog-2.jpg"
                          alt=""
                        />
                        <a
                          className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                          href
                        >
                          Recommendations
                        </a>
                      </div>
                      <div className="p-4">
                        <div className="d-flex mb-3">
                          <small className="me-3">
                            <i className="far fa-user text-primary me-2" />
                            Grimm
                          </small>
                          <small>
                            <i className="far fa-calendar-alt text-primary me-2" />
                            01 Jan, 2045
                          </small>
                        </div>
                        <h4 className="mb-3">
                          What Are the Common Mistakes to Avoid in Residence
                          Visa Applications?
                        </h4>
                        <p>
                          Applying for a visa to reside in Dubai is essential
                          for those intending to work and live for some time in
                          the UAE.
                        </p>
                        <a className="text-uppercase" href="/Blog-page?id=5">
                          Read More <i className="bi bi-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 wow slideInUp" data-wow-delay="0.6s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                      <div className="blog-img position-relative overflow-hidden">
                        <img
                          className="img-fluid"
                          src="assets/img/blog-3.jpg"
                          alt=""
                        />
                        <a
                          className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                          href
                        >
                          Essentials
                        </a>
                      </div>
                      <div className="p-4">
                        <div className="d-flex mb-3">
                          <small className="me-3">
                            <i className="far fa-user text-primary me-2" />
                            Brad Hawk
                          </small>
                          <small>
                            <i className="far fa-calendar-alt text-primary me-2" />
                            22 Aug, 2024
                          </small>
                        </div>
                        <h4 className="mb-3">
                          What Are the Key Components of Effective Due Diligence
                          Services in Dubai?
                        </h4>
                        <p>
                          Due diligence is an essential business practice,
                          especially when entering new markets or undertaking
                          significant investments.
                        </p>
                        <a className="text-uppercase" href="/Blog-page?id=6">
                          Read More <i className="bi bi-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 wow slideInUp" data-wow-delay="0.1s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                      <div className="blog-img position-relative overflow-hidden">
                        <img
                          className="img-fluid"
                          src="assets/img/blog-1.jpg"
                          alt=""
                        />
                        <a
                          className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                          href
                        >
                          Accounting
                        </a>
                      </div>
                      <div className="p-4">
                        <div className="d-flex mb-3">
                          <small className="me-3">
                            <i className="far fa-user text-primary me-2" />
                            John Doe
                          </small>
                          <small>
                            <i className="far fa-calendar-alt text-primary me-2" />
                            01 Jan, 2045
                          </small>
                        </div>
                        <h4 className="mb-3">Accounting &amp; Bookkeeping</h4>
                        <p>
                          Dolor et eos labore stet justo sed est sed sed sed
                          dolor stet amet
                        </p>
                        <a className="text-uppercase" href>
                          Read More <i className="bi bi-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 wow slideInUp" data-wow-delay="0.6s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                      <div className="blog-img position-relative overflow-hidden">
                        <img
                          className="img-fluid"
                          src="assets/img/blog-2.jpg"
                          alt=""
                        />
                        <a
                          className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                          href
                        >
                          Accounting
                        </a>
                      </div>
                      <div className="p-4">
                        <div className="d-flex mb-3">
                          <small className="me-3">
                            <i className="far fa-user text-primary me-2" />
                            John Doe
                          </small>
                          <small>
                            <i className="far fa-calendar-alt text-primary me-2" />
                            01 Jan, 2045
                          </small>
                        </div>
                        <h4 className="mb-3">Accounting &amp; Bookkeeping</h4>
                        <p>
                          Dolor et eos labore stet justo sed est sed sed sed
                          dolor stet amet
                        </p>
                        <a className="text-uppercase" href>
                          Read More <i className="bi bi-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 wow slideInUp" data-wow-delay="0.1s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                      <div className="blog-img position-relative overflow-hidden">
                        <img
                          className="img-fluid"
                          src="assets/img/blog-3.jpg"
                          alt=""
                        />
                        <a
                          className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                          href
                        >
                          Accounting
                        </a>
                      </div>
                      <div className="p-4">
                        <div className="d-flex mb-3">
                          <small className="me-3">
                            <i className="far fa-user text-primary me-2" />
                            John Doe
                          </small>
                          <small>
                            <i className="far fa-calendar-alt text-primary me-2" />
                            01 Jan, 2045
                          </small>
                        </div>
                        <h4 className="mb-3">Accounting &amp; Bookkeeping</h4>
                        <p>
                          Dolor et eos labore stet justo sed est sed sed sed
                          dolor stet amet
                        </p>
                        <a className="text-uppercase" href>
                          Read More <i className="bi bi-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 wow slideInUp" data-wow-delay="0.6s">
                    <div className="blog-item bg-light rounded overflow-hidden">
                      <div className="blog-img position-relative overflow-hidden">
                        <img
                          className="img-fluid"
                          src="assets/img/blog-1.jpg"
                          alt=""
                        />
                        <a
                          className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                          href
                        >
                          Accounting
                        </a>
                      </div>
                      <div className="p-4">
                        <div className="d-flex mb-3">
                          <small className="me-3">
                            <i className="far fa-user text-primary me-2" />
                            John Doe
                          </small>
                          <small>
                            <i className="far fa-calendar-alt text-primary me-2" />
                            01 Jan, 2024
                          </small>
                        </div>
                        <h4 className="mb-3">Accounting &amp; Bookkeeping</h4>
                        <p>
                          Dolor et eos labore stet justo sed est sed sed sed
                          dolor stet amet
                        </p>
                        <a className="text-uppercase" href>
                          Read More <i className="bi bi-arrow-right" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 wow slideInUp" data-wow-delay="0.1s">
                    <nav aria-label="Page navigation">
                      <ul className="pagination pagination-lg m-0">
                        <li className="page-item disabled">
                          <a
                            className="page-link rounded-0"
                            href="#"
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">
                              <i className="bi bi-arrow-left" />
                            </span>
                          </a>
                        </li>
                        <li className="page-item active">
                          <a className="page-link" href="#">
                            1
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            2
                          </a>
                        </li>
                        <li className="page-item">
                          <a className="page-link" href="#">
                            3
                          </a>
                        </li>
                        <li className="page-item">
                          <a
                            className="page-link rounded-0"
                            href="#"
                            aria-label="Next"
                          >
                            <span aria-hidden="true">
                              <i className="bi bi-arrow-right" />
                            </span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
              {/* Blog list End */}
              {/* Sidebar Start */}
              <div className="col-lg-4">
                {/* Search Form Start */}
                <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control p-3"
                      placeholder="Keyword"
                    />
                    <button className="btn btn-primary px-4">
                      <i className="bi bi-search" />
                    </button>
                  </div>
                </div>
                {/* Search Form End */}
                {/* Category Start */}
                <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="mb-0">Categories</h3>
                  </div>
                  <div className="link-animated d-flex flex-column justify-content-start">
                    <a
                      className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2"
                      href="#"
                    >
                      <i className="bi bi-arrow-right me-2" />
                      Business Setup
                    </a>
                    <a
                      className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2"
                      href="#"
                    >
                      <i className="bi bi-arrow-right me-2" />
                      Accounting &amp; Taxation
                    </a>
                    <a
                      className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2"
                      href="#"
                    >
                      <i className="bi bi-arrow-right me-2" />
                      Statutory &amp; Taxation
                    </a>
                    <a
                      className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2"
                      href="#"
                    >
                      <i className="bi bi-arrow-right me-2" />
                      People &amp; Payroll
                    </a>
                    <a
                      className="h5 fw-semi-bold bg-light rounded py-2 px-3 mb-2"
                      href="#"
                    >
                      <i className="bi bi-arrow-right me-2" />
                      Support
                    </a>
                  </div>
                </div>
                {/* Category End */}
                {/* Recent Post Start */}
                <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="mb-0">Recent Post</h3>
                  </div>
                  <div className="d-flex rounded overflow-hidden mb-3">
                    <img
                      className="img-fluid"
                      src="assets/img/blog-1.jpg"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                    <a
                      href
                      className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0"
                    >
                      With the ever-changing business world, new businesses face
                      unique issues
                    </a>
                  </div>
                  <div className="d-flex rounded overflow-hidden mb-3">
                    <img
                      className="img-fluid"
                      src="assets/img/blog-2.jpg"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                    <a
                      href
                      className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0"
                    >
                      Managing payroll is a crucial and challenging job for
                      firms of any size.
                    </a>
                  </div>
                  <div className="d-flex rounded overflow-hidden mb-3">
                    <img
                      className="img-fluid"
                      src="assets/img/blog-3.jpg"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                    <a
                      href
                      className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0"
                    >
                      Applying for a visa to reside in Dubai is essential for
                      those intending to work and live
                    </a>
                  </div>
                  <div className="d-flex rounded overflow-hidden mb-3">
                    <img
                      className="img-fluid"
                      src="assets/img/blog-1.jpg"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                    <a
                      href
                      className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0"
                    >
                      The complex tax landscape can be challenging for
                      businesses and individuals.
                    </a>
                  </div>
                  <div className="d-flex rounded overflow-hidden mb-3">
                    <img
                      className="img-fluid"
                      src="assets/img/blog-2.jpg"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                    <a
                      href
                      className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0"
                    >
                      Applying for a visa to reside in Dubai is essential for
                      those intending to work
                    </a>
                  </div>
                  <div className="d-flex rounded overflow-hidden mb-3">
                    <img
                      className="img-fluid"
                      src="assets/img/blog-3.jpg"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                      alt=""
                    />
                    <a
                      href
                      className="h5 fw-semi-bold d-flex align-items-center bg-light px-3 mb-0"
                    >
                      Due diligence is an essential business practice
                    </a>
                  </div>
                </div>
                {/* Recent Post End */}
                {/* Image Start */}
                <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                  <img
                    src="assets/img/blog-1.jpg"
                    alt=""
                    className="img-fluid rounded"
                  />
                </div>
                {/* Image End */}
                {/* Tags Start */}
                <div className="mb-5 wow slideInUp" data-wow-delay="0.1s">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="mb-0">Tag Cloud</h3>
                  </div>
                  <div className="d-flex flex-wrap m-n1">
                    <a href className="btn btn-light m-1">
                      Design
                    </a>
                    <a href className="btn btn-light m-1">
                      Development
                    </a>
                    <a href className="btn btn-light m-1">
                      Marketing
                    </a>
                    <a href className="btn btn-light m-1">
                      SEO
                    </a>
                    <a href className="btn btn-light m-1">
                      Writing
                    </a>
                    <a href className="btn btn-light m-1">
                      Consulting
                    </a>
                    <a href className="btn btn-light m-1">
                      Design
                    </a>
                    <a href className="btn btn-light m-1">
                      Development
                    </a>
                    <a href className="btn btn-light m-1">
                      Marketing
                    </a>
                    <a href className="btn btn-light m-1">
                      SEO
                    </a>
                    <a href className="btn btn-light m-1">
                      Writing
                    </a>
                    <a href className="btn btn-light m-1">
                      Consulting
                    </a>
                  </div>
                </div>
                {/* Tags End */}
                {/* Plain Text Start */}
                <div className="wow slideInUp" data-wow-delay="0.1s">
                  <div className="section-title section-title-sm position-relative pb-3 mb-4">
                    <h3 className="mb-0">Plain Text</h3>
                  </div>
                  <div
                    className="bg-light text-center"
                    style={{ padding: "30px" }}
                  >
                    <p>
                      Vero sea et accusam justo dolor accusam lorem consetetur,
                      dolores sit amet sit dolor clita kasd justo, diam accusam
                      no sea ut tempor magna takimata, amet sit et diam dolor
                      ipsum amet diam
                    </p>
                    <a href className="btn btn-primary py-2 px-4">
                      Read More
                    </a>
                  </div>
                </div>
                {/* Plain Text End */}
              </div>
              {/* Sidebar End */}
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Vendor Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5 mb-5">
            <div className="bg-white">
              <div className="owl-carousel vendor-carousel">
                <img src="assets/img/vendor-1.jpg" alt="" />
                <img src="assets/img/vendor-2.jpg" alt="" />
                <img src="assets/img/vendor-3.jpg" alt="" />
                <img src="assets/img/vendor-4.jpg" alt="" />
                <img src="assets/img/vendor-5.jpg" alt="" />
                <img src="assets/img/vendor-6.jpg" alt="" />
                <img src="assets/img/vendor-7.jpg" alt="" />
                <img src="assets/img/vendor-8.jpg" alt="" />
                <img src="assets/img/vendor-9.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* Vendor End */}
        {/* Footer Start */}

        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default Blog;
