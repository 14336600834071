import React, { Fragment } from "react";
import Navmenu from "./navmenu";
import ChatBot from "react-chatbotify";
import "../App.css";
import { Helmet } from "react-helmet";

function Index() {
  const [form, setForm] = React.useState({});
  const formStyle = {
    marginTop: 10,
    marginLeft: 20,
    border: "1px solid #491d8d",
    padding: 10,
    borderRadius: 5,
    maxWidth: 300,
  };

  const flow = {
    start: {
      message:
        "Welcome to our Business Consulting Services! How can we assist you today?",
      function: (params) => setForm({ ...form, name: params.userInput }),
      path: "ask_service",
    },
    ask_service: {
      message: (params) => `What service would like?`,

      function: (params) => setForm({ ...form, service: params.userInput }),
      path: "ask_choice",
    },
    ask_choice: {
      message: "Please select the services you would like.",
      checkboxes: {
        items: [
          "Business Set Up",
          "Accounting & Taxation",
          "Statutory Compliance",
          "Support",
        ],
        min: 1,
      },
      chatDisabled: true,
      function: (params) =>
        setForm({ ...form, service_choices: params.userInput }),
      path: "end",
    },
    end: {
      message: "Thank you for your interest, we will get back to you shortly!",
      component: (
        <div style={formStyle}>
          <p>Name: {form.name}</p>
          <p>Service: {form.service}</p>
          <p>Service Choices: {form.service_choices}</p>
        </div>
      ),
      options: ["New Conversation"],
      chatDisabled: true,
      path: "start",
    },
  };

  return (
    <Fragment>
      <div>
        <Navmenu />
        <Helmet>
          <title>
            Business Formation and Accounting & Taxation Services | OGM
            Consultants
          </title>
          <meta
            name="description"
            content="Looking to start a business? Our comprehensive services offer expert assistance in business formation, accounting, and taxation. From LLCs and corporations to bookkeeping and tax strategies, we provide tailored solutions to ensure your business is set up for success and compliant with all financial regulations. Get the support you need to make informed decisions and achieve long-term growth."
          />
        </Helmet>
        <div
          id="header-carousel"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active image">
              <img
                className="image w-100"
                src="assets/img/carousel-1.jpg"
                alt="Image"
              />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ "max-width": "900px" }}>
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    Swift &amp; Accurate
                  </h5>
                  <h3 className="display-1 text-white mb-md-4 animated zoomIn">
                    Business Formation and Accounting &amp; Taxation
                  </h3>
                  <a
                    href="Quote"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Get Free Quote
                  </a>
                  <a
                    href="Contact"
                    className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-item image">
              <img
                className="image w-100"
                src="assets/img//carousel-2.jpg"
                alt="Image"
              />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ "max-width": "900px" }}>
                  <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                    world wide
                  </h5>
                  <h3 className="display-1 text-white mb-md-4 animated zoomIn">
                    Company Formation &amp; More
                  </h3>
                  <a
                    href="Quote"
                    className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                  >
                    Get Free Quote
                  </a>
                  <a
                    href
                    className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button> */}
        </div>
        {/* Navbar & Carousel End */}
        {/* Full Screen Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1}>
          <div className="modal-dialog modal-fullscreen">
            <div
              className="modal-content"
              style={{ background: "rgba(9, 30, 62, .7)" }}
            >
              <div className="modal-header border-0">
                <button
                  type="button"
                  className="btn bg-white btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body d-flex align-items-center justify-content-center">
                <div className="input-group" style={{ "max-width": "600px" }}>
                  <input
                    type="text"
                    className="form-control bg-transparent border-primary p-3"
                    placeholder="Type search keyword"
                  />
                  <button className="btn btn-primary px-4">
                    <i className="bi bi-search" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Full Screen Search End */}
        {/* Facts Start */}
        <div className="container-fluid facts py-5 pt-lg-0">
          <div className="container py-5 pt-lg-0">
            <div className="row gx-0">
              <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
                <div
                  className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                  style={{ height: "150px" }}
                >
                  <div
                    className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-users text-primary" />
                  </div>
                  <div className="ps-4">
                    <h5 className="text-white mb-0">Happy Clients</h5>
                    <h1 className="text-white mb-0" data-toggle="counter-up">
                      1250
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                <div
                  className="bg-light shadow d-flex align-items-center justify-content-center p-4"
                  style={{ height: "150px" }}
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded mb-2"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-check text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="text-primary mb-0">Projects Done</h5>
                    <h1 className="mb-0" data-toggle="counter-up">
                      1850
                    </h1>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
                <div
                  className="bg-primary shadow d-flex align-items-center justify-content-center p-4"
                  style={{ height: "150px" }}
                >
                  <div
                    className="bg-white d-flex align-items-center justify-content-center rounded mb-2"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-award text-primary" />
                  </div>
                  <div className="ps-4">
                    <h5 className="text-white mb-0">Won Awards</h5>
                    <h1 className="text-white mb-0" data-toggle="counter-up">
                      8
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Facts Start */}
        {/* About Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    About Us
                  </h5>
                  <h1 className="mb-0">
                    Secure your tomorrow with our expert financial guidance
                    today
                  </h1>
                </div>
                <p className="mb-4">
                  Welcome to OGM Business Consultants, your premier destination
                  for comprehensive financial and business advisory services. At
                  OGMBC, we believe in empowering businesses to thrive and
                  succeed in today's dynamic and competitive landscape.
                </p>
                <div className="row g-0 mb-3">
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Award Winning
                    </h5>
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Professional Staff
                    </h5>
                  </div>
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Constant Support
                    </h5>
                    <h5 className="mb-3">
                      <i className="fa fa-check text-primary me-3" />
                      Fair Prices
                    </h5>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center mb-4 wow fadeIn"
                  data-wow-delay="0.6s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-phone-alt text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Call to ask any question</h5>
                    <h4 className="text-primary mb-0">+971502923136</h4>
                  </div>
                </div>
                <a
                  href="Quote"
                  className="btn btn-primary py-3 px-5 mt-3 wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  Request A Quote
                </a>
              </div>
              <div className="col-lg-5" style={{ "min-height": "500px" }}>
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.9s"
                    src="assets/img//about.jpg"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
        {/* Features Start */}
        <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "1000px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Why Choose Us
              </h5>
              <h1 className="mb-0">
                we are committed to making a positive impact on our clients'
                businesses and helping them navigate the complexities of today's
                business environment with confidence and success{" "}
              </h1>
            </div>
            <div className="row g-5">
              <div className="col-lg-4">
                <div className="row g-5">
                  <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                    <div
                      className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                      style={{ width: "60px", height: "60px" }}
                    >
                      <i className="fa fa-cubes text-white" />
                    </div>
                    <h4>Integrity</h4>
                    <p className="mb-0">
                      We uphold the highest standards of integrity, honesty, and
                      ethical conduct in all aspects of our work. Trust and
                      transparency are at the core of everything we do.{" "}
                    </p>
                  </div>
                  <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                    <div
                      className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                      style={{ width: "60px", height: "60px" }}
                    >
                      <i className="fa fa-award text-white" />
                    </div>
                    <h4>Excellence</h4>
                    <p className="mb-0">
                      We are committed to delivering excellence in all our
                      services, striving for continuous improvement, and
                      exceeding our clients' expectations at every opportunity.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4  wow zoomIn"
                data-wow-delay="0.9s"
                style={{ "min-height": "350px" }}
              >
                <div className="position-relative h-100">
                  <img
                    className="position-absolute w-100 h-100 rounded wow zoomIn"
                    data-wow-delay="0.1s"
                    src="assets/img//feature.jpg"
                    style={{ "object-fit": "cover" }}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row g-5">
                  <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                    <div
                      className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                      style={{ width: "60px", height: "60px" }}
                    >
                      <i className="fa fa-users-cog text-white" />
                    </div>
                    <h4>Collaboration</h4>
                    <p className="mb-0">
                      We believe in the power of collaboration and teamwork. By
                      working closely with our clients and leveraging our
                      collective expertise, we can achieve greater success
                      together.
                    </p>
                  </div>
                  <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                    <div
                      className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                      style={{ width: "60px", height: "60px" }}
                    >
                      <i className="fa fa-phone-alt text-white" />
                    </div>
                    <h4>Client-Centricity </h4>
                    <p className="mb-0">
                      Our clients are at the heart of everything we do. We are
                      dedicated to understanding their unique needs, providing
                      tailored solutions, and delivering exceptional value and
                      service at every interaction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Features Start */}
        {/* Service Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Our Services
              </h5>
              <h1 className="mb-0">
                Custom Corporate Solutions for Your Successful Business
              </h1>
            </div>
            <div className="row g-5">
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <a href="Business-setup">
                  <div
                    id="business-setup"
                    className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center"
                  >
                    <div className="service-icon">
                      <i className="fa fa-building text-white" />
                    </div>
                    <a href="Business-setup">
                      <h4 className="mb-3">Business Setup</h4>
                    </a>
                    <p className="m-0">
                      Specializing in seamless multi-country business formation
                      for global ventures and expansions
                    </p>
                  </div>
                </a>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <a href="Account-taxation">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div className="service-icon">
                      <i className="fa fa-chart-pie text-white" />
                    </div>
                    <a href="Account-taxation">
                      <h4 className="mb-3">Accounting &amp; Taxation</h4>
                    </a>
                    <p className="m-0">
                      At OGM, we expertise in Accounting and Taxation services
                      for comprehensive financial management solutions.
                    </p>
                  </div>
                </a>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.9s"
              >
                <a href="#">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div className="service-icon">
                      <i className="fa fa-balance-scale text-white" />
                    </div>
                    <a href="#">
                      <h4 className="mb-3">Statutory Compliance</h4>
                    </a>
                    <p className="m-0">
                      We offer comprehensive statutory compliance solutions for
                      seamless regulatory adherence and business success.
                    </p>
                  </div>
                </a>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.3s"
              >
                <a href="Payroll" className="checkUrl">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div className="service-icon">
                      <i className="fas fa-users text-white" />
                    </div>
                    <a href="Payroll">
                      <h4 className="mb-3">People &amp; Payroll</h4>
                    </a>
                    <p className="m-0">
                      Specialized in comprehensive people and payroll services
                      like International HR and Global Payroll
                    </p>
                  </div>
                </a>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.6s"
              >
                <a href="#">
                  <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                    <div className="service-icon">
                      <i className="fa fa-question-circle text-white" />
                    </div>
                    <a href="#">
                      <h4 className="mb-3">Support</h4>
                    </a>
                    <p className="m-0">
                      We offer Support servives like Bank Accounting Opening and
                      Annual Renewal services with dedication and
                      professionality.
                    </p>
                  </div>
                </a>
              </div>
              <div
                className="col-lg-4 col-md-6 wow zoomIn"
                data-wow-delay="0.9s"
              >
                <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                  <h3 className="text-white mb-3">Call Us For Quote</h3>
                  <p className="text-white mb-3">
                    We are available via the contact below for your convenience
                    and prompt assistance.
                  </p>
                  <h2 className="text-white mb-0">+971502923136</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Service End */}
        {/* Pricing Plan Start
<div class="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
<div class="container py-5">
  <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style="max-width: 600px;">
      <h5 class="fw-bold text-primary text-uppercase">Pricing Plans</h5>
      <h1 class="mb-0">We are Offering Competitive Prices for USA Company Formation</h1>
  </div>
  <div class="row g-0">
      <div class="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
          <div class="bg-light rounded">
              <div class="border-bottom py-4 px-5 mb-4">
                  <h4 class="text-primary mb-1">Basic Service</h4>
                  <small class="text-uppercase">For Small Size Business</small>
              </div>
              <div class="p-5 pt-0">
                  <h1 class="display-5 mb-3">
                      <small class="align-top" style="font-size: 22px; line-height: 45px;">$</small>49.00<small
                          class="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small>
                  </h1>
                  <div class="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i class="fa fa-check text-primary pt-1"></i></div>
                  <div class="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i class="fa fa-check text-primary pt-1"></i></div>
                  <div class="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i class="fa fa-times text-danger pt-1"></i></div>
                  <div class="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i class="fa fa-times text-danger pt-1"></i></div>
                  <a href="" class="btn btn-primary py-2 px-4 mt-4">Order Now</a>
              </div>
          </div>
      </div>
      <div class="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
          <div class="bg-white rounded shadow position-relative" style="z-index: 1;">
              <div class="border-bottom py-4 px-5 mb-4">
                  <h4 class="text-primary mb-1">Standard Service</h4>
                  <small class="text-uppercase">For Medium Size Business</small>
              </div>
              <div class="p-5 pt-0">
                  <h1 class="display-5 mb-3">
                      <small class="align-top" style="font-size: 22px; line-height: 45px;">$</small>99.00<small
                          class="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small>
                  </h1>
                  <div class="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i class="fa fa-check text-primary pt-1"></i></div>
                  <div class="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i class="fa fa-check text-primary pt-1"></i></div>
                  <div class="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i class="fa fa-check text-primary pt-1"></i></div>
                  <div class="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i class="fa fa-times text-danger pt-1"></i></div>
                  <a href="" class="btn btn-primary py-2 px-4 mt-4">Order Now</a>
              </div>
          </div>
      </div>
      <div class="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
          <div class="bg-light rounded">
              <div class="border-bottom py-4 px-5 mb-4">
                  <h4 class="text-primary mb-1">Advanced Service</h4>
                  <small class="text-uppercase">For Large Size Business</small>
              </div>
              <div class="p-5 pt-0">
                  <h1 class="display-5 mb-3">
                      <small class="align-top" style="font-size: 22px; line-height: 45px;">$</small>149.00<small
                          class="align-bottom" style="font-size: 16px; line-height: 40px;">/ Month</small>
                  </h1>
                  <div class="d-flex justify-content-between mb-3"><span>HTML5 & CSS3</span><i class="fa fa-check text-primary pt-1"></i></div>
                  <div class="d-flex justify-content-between mb-3"><span>Bootstrap v5</span><i class="fa fa-check text-primary pt-1"></i></div>
                  <div class="d-flex justify-content-between mb-3"><span>Responsive Layout</span><i class="fa fa-check text-primary pt-1"></i></div>
                  <div class="d-flex justify-content-between mb-2"><span>Cross-browser Support</span><i class="fa fa-check text-primary pt-1"></i></div>
                  <a href="" class="btn btn-primary py-2 px-4 mt-4">Order Now</a>
              </div>
          </div>
      </div>
  </div>
</div>
</div>
Pricing Plan End */}
        {/* Quote Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div className="row g-5">
              <div className="col-lg-7">
                <div className="section-title position-relative pb-3 mb-5">
                  <h5 className="fw-bold text-primary text-uppercase">
                    Request A Quote
                  </h5>
                  <h1 className="mb-0">
                    Need A Free Quote? Please Feel Free to Contact Us
                  </h1>
                </div>
                <div className="row gx-3">
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                    <h5 className="mb-4">
                      <i className="fa fa-reply text-primary me-3" />
                      Reply within 24 hours
                    </h5>
                  </div>
                  <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                    <h5 className="mb-4">
                      <i className="fa fa-phone-alt text-primary me-3" />
                      24 hrs telephone support
                    </h5>
                  </div>
                </div>
                <p className="mb-4">
                  We promptly respond to customer inquiries for quotations,
                  providing a free and transparent request process. Our
                  commitment is to deliver accurate and detailed information,
                  ensuring clients receive the best insights and options
                  tailored to their needs. Your satisfaction is our priority,
                  and we strive to make the quotation process seamless,
                  informative, and cost-free for a hassle-free experience.
                </p>
                <div
                  className="d-flex align-items-center mt-2 wow zoomIn"
                  data-wow-delay="0.6s"
                >
                  <div
                    className="bg-primary d-flex align-items-center justify-content-center rounded"
                    style={{ width: "60px", height: "60px" }}
                  >
                    <i className="fa fa-phone-alt text-white" />
                  </div>
                  <div className="ps-4">
                    <h5 className="mb-2">Call to ask any question</h5>
                    <h4 className="text-primary mb-0">+971502923136</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div
                  className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
                  data-wow-delay="0.9s"
                >
                  <form action="https://formspree.io/f/xjvnjnea" method="post">
                    <div className="row g-3">
                      <div className="col-xl-12">
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          name="name"
                          placeholder="Your Name"
                          style={{ height: "55px" }}
                        />
                      </div>
                      <div className="col-12">
                        <input
                          type="email"
                          className="form-control bg-light border-0"
                          name="email"
                          placeholder="Your Email"
                          style={{ height: "55px" }}
                        />
                      </div>
                      <div className="col-12">
                        <select
                          className="form-select bg-light border-0"
                          name="service"
                          style={{ height: "55px" }}
                        >
                          <option selected>Select A Service</option>
                          <option value={1}>Business Set Up</option>
                          <option value={2}>Accounting &amp; Taxation</option>
                          <option value={3}>Statutory Compliance</option>
                          <option value={3}>People &amp; Payroll</option>
                          <option value={3}>Support</option>
                        </select>
                      </div>
                      <div className="col-xl-12">
                        <input
                          type="text"
                          className="form-control bg-light border-0"
                          name="name"
                          placeholder="Sub-Service"
                          style={{ height: "55px" }}
                        />
                      </div>
                      <div className="col-12">
                        <textarea
                          className="form-control bg-light border-0"
                          name="message"
                          rows={3}
                          placeholder="Message"
                          defaultValue={""}
                        />
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-dark w-100 py-3"
                          type="submit"
                        >
                          Request A Quote
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Quote End */}
        {/* Blog Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div className="container py-5">
            <div
              className="section-title text-center position-relative pb-3 mb-5 mx-auto"
              style={{ "max-width": "600px" }}
            >
              <h5 className="fw-bold text-primary text-uppercase">
                Latest Blog
              </h5>
              <h1 className="mb-0">
                Read The Latest Articles from Our Blog Post
              </h1>
            </div>
            <div className="row g-5">
              <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                <div className="blog-item bg-light rounded overflow-hidden">
                  <div className="blog-img position-relative overflow-hidden">
                    <img
                      className="img-fluid"
                      src="assets/img//blog-1.jpg"
                      alt
                    />
                    <a
                      className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                      href
                    >
                      Business Formation
                    </a>
                  </div>
                  <div className="p-4">
                    <div className="d-flex mb-3">
                      <small className="me-3">
                        <i className="far fa-user text-primary me-2" />
                        Jake
                      </small>
                      <small>
                        <i className="far fa-calendar-alt text-primary me-2" />
                        21 Aug, 2024
                      </small>
                    </div>
                    <h4 className="mb-3">
                      Why Do Startups Need Business Setup Consultants More Than
                      Ever?
                    </h4>
                    <p>
                      With the ever-changing business world, new businesses face
                      unique issues that require expert understanding and
                      guidance.
                    </p>
                    <a className="text-uppercase" href="/Blog-page?id=1">
                      Read More <i className="bi bi-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                <div className="blog-item bg-light rounded overflow-hidden">
                  <div className="blog-img position-relative overflow-hidden">
                    <img
                      className="img-fluid"
                      src="assets/img//blog-2.jpg"
                      alt
                    />
                    <a
                      className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                      href
                    >
                      Advantages
                    </a>
                  </div>
                  <div className="p-4">
                    <div className="d-flex mb-3">
                      <small className="me-3">
                        <i className="far fa-user text-primary me-2" />
                        Glen
                      </small>
                      <small>
                        <i className="far fa-calendar-alt text-primary me-2" />
                        22 Aug, 2024
                      </small>
                    </div>
                    <h4 className="mb-3">
                      What Are the Key Benefits of Outsourcing Payroll Services?
                    </h4>
                    <p>
                      Managing payroll is a crucial and challenging job for
                      firms of any size. The process involves calculating
                      employee wages and taxes for employees, taking care of
                      deductions, and maintaining accurate data.
                    </p>
                    <a className="text-uppercase" href="/Blog-page?id=2">
                      Read More <i className="bi bi-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow slideInUp" data-wow-delay="0.9s">
                <div className="blog-item bg-light rounded overflow-hidden">
                  <div className="blog-img position-relative overflow-hidden">
                    <img
                      className="img-fluid"
                      src="assets/img//blog-3.jpg"
                      alt
                    />
                    <a
                      className="position-absolute top-0 start-0 bg-primary text-white rounded-end mt-5 py-2 px-4"
                      href
                    >
                      Guidelines
                    </a>
                  </div>
                  <div className="p-4">
                    <div className="d-flex mb-3">
                      <small className="me-3">
                        <i className="far fa-user text-primary me-2" />
                        Park
                      </small>
                      <small>
                        <i className="far fa-calendar-alt text-primary me-2" />
                        22 Aug, 2024
                      </small>
                    </div>
                    <h4 className="mb-3">
                      What Are the Common Mistakes to Avoid in Residence Visa
                      Applications?
                    </h4>
                    <p>
                      Applying for a visa to reside in Dubai is essential for
                      those intending to work and live for some time in the UAE.{" "}
                    </p>
                    <a className="text-uppercase" href="/Blog-page?id=3">
                      Read More <i className="bi bi-arrow-right" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog Start */}
        {/* Vendor Start */}
        <div
          className="container-fluid py-5 wow fadeInUp"
          data-wow-delay="0.1s"
        ></div>
        {/* Vendor End */}
        <div className="chat-logo">
          <ChatBot flow={flow} />
        </div>
        <div className="whatsapp-icon">
          <a href="https://wa.me/971502923136" target="_blank">
            <img src="assets/img//whatsapp.svg" alt="WhatsApp" />
          </a>
        </div>
        {/* Footer Start */}

        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square rounded back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
    </Fragment>
  );
}
export default Index;
